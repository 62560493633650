body {
    /* background-color: #222; */
    font-family: "Poppins", sans-serif;
}
.istoryContainer{
  background-color: #060606;
  color: #fff;
   
  display:flex;
  align-items: center;
  justify-content: center;
  
}
.storyBoard{
  height:calc(80vh)
}
/* .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
} */

.card {
    background-color: #333;
    color: #fff;
    margin-bottom: 20px;
}

.card-img {
    height: 200px;
    object-fit: cover;
}

.navigation {
    margin-bottom: 20px;
}

.dialogues {
    background-color: #333;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}

.dot-animation {
    /* position: absolute; */
    /* top: 0;
    right: 0; */
    margin-top: 2px;
    display: inline-block;
    /* width: 30px; */
    height: 10px;
  }
  
  .dot-animation span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    margin-right: 4px;
    opacity: 0;
    animation: dotAnimation 1s infinite;
  }
  
  .dot-animation span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot-animation span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot-animation span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dotAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

.loading-container {
  text-align: center;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  gap:10px;
}

  .loading-text {
    color: #ffffff;
    font-size: 18px;
    animation: fadeIn 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  