.main-container {
  margin: 1rem;
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 88px;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidemenu {
  width: 260px;
  padding: 10px;
  background-color: #202123;
}

.sidemenu-button {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.sidemenu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidemenu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.chatbox {
  flex: 1;
  background-color: #343541;
  position: relative;
}

.chat-log {
  position: relative;
  text-align: left;
  overflow-y: auto;
  height: calc(100% - 62px);
}

.image-log {
  text-align: left;
  height: 93%;
  overflow-y: auto;
}

.chat-input-holder, .image-input-holder {
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 10px;
}

.chat-input-holder button {
	background: #40414f;
	border: solid thin white;
	border-radius: 5px;
	height: 33px;
	margin: 5px;
	color: white;
}

.chat-input-textarea {
  background-color: #40414f;
  /* width: 99%; */
  width: -webkit-fill-available;
  padding: 15px;
  margin-top: 16px;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0.25);
}

.btn-form-submit {
  background-color: #2731c3;
  padding: 15px;
  margin-top: 8px;
  border-radius: 5px;
  color: white;
  font-size: 1em;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0.25);
}

.image-input-textarea {
  background-color: #40414f;
  width: 99%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 1.25em;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgb(0, 0, 0, 0.25);
}

.chat-message {
  padding: 20px;
}

.chat-message.chatgpt {
  background-color: #444654;
}

.chat-message.customer {
  background-color: #343541;
}

.chat-message-center {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-left: 12px;
}

.avatar {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.chatgpt {
  background: #0da37f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.customer {
  background: #d54210;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.message {
  padding-left: 20px;
  margin-top: 10px;
  width: 100%;
}

.message-img {
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
}

.submit-button {
  margin: 10px;
}

.api-submit-button {
  margin: 10px;
  width: 80%;
}

.api-reference-link {
  margin: 10px;
  width: 80%;
  align-self: flex-end;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.about-us, .about-iframe {
  height:100%;
  width:100%;
  border:none;
  margin: 0;
  padding: 0;
  overflow:hidden;
}

.upload-input {
  margin: 10px;
  padding: 10px;
}

.logout-button {
  border: none;
  cursor: pointer;
}

.csv-upload-first {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.csv-upload-first h1, .csv-upload-first h2 {
  margin: 0;
}

.csv-upload-first > div {
	display: flex;
	gap: 32px;
}

.csv-upload-first h2 {
	font-size: 20px;
}

.csv-upload-first > div > div{
	display: flex;
	flex-direction: column;
}

/* Data Analytics visualizations */

.VictoryContainer {
	max-height: 500px !important;
}

.history{
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
  height: calc(100vh - 290px);
}
.history-container{
overflow-y: auto;
}
.history-container::-webkit-scrollbar {
  display: none;
}
.history_heading{
  display: flex;
  justify-content:flex-start;

}
.history_topics{
  display: flex;
  justify-content:flex-start;
  background-color: #40414f;
  margin-top:5px;
  border-radius: 5px;
  padding:15px;

}
.history_text{
  margin:0px;
  text-align: left;
}