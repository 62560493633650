
/* .scroll {
    width: 200px;
    height: 400px;
     background: red;
    overflow: scroll; 
 } */
 .scroll::-webkit-scrollbar {
     width: 5px;
     background: #111827;
 }
 
 .scroll::-webkit-scrollbar-track {
     /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
     border-radius: 8px;
     background: #1f2937;
 }
 
 .scroll::-webkit-scrollbar-thumb {
     border-radius: 8px;
     /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
     background: #111827;
 } 