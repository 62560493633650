/* .loginContainer {
	/* This is probably not good. 
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
} */

@media screen and (min-width: 900px) {

	.loginContainer {
		/* This is probably not good. */
		position: absolute; 
		 top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}	
}

#background {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	pointer-events: none;
	z-index: -1;

	background: url("../assets/images/waves.png") #33336f;
	background-position: center center;
	background-size: cover;
}

@media screen and (max-width: 900px) {
	#background {
		display: none;
	}

	body {
		position: unset;
		display: block;
	}
}

#login-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 48px;
	gap: 96px;

	width: 840px;
	height: 518px;

	background: #fafafa;
	border: 1px solid #bcbacd;
	border-radius: 16px;
}

#login {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 32px;

	width: 331px;
	height: 450px;
}

#login .txt h1 {
	font-size: 36px;
}

#login .txt p {
	font-size: 18px;
}

#login .txt h1,
#login p {
	margin: 0;
	text-align: center;
}

#login > p {
	font-size: 18px;
	text-align: center;
}

#login form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 16px;

	width: 331px;
	height: 176px;
}

#login form input {
	width: 305px;
	height: 48px;
	padding: 0 12px;

	font-size: 16px;

	border: 1px solid #bcbacd;
	border-radius: 24px;
}

#login form input:not([type="submit"]) {
	background: #ffffff;
	color: #505050;
}

#login form input[type="submit"] {
	width: 331px;
	background: var(--alt-color);
	color: #ffffff;
}

#login a,
#login a:visited {
	color: black;
}

#security {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 40px;

	width: 380px;
	height: 433px;
}

#security p {
	font-size: 16px;
	text-align: center;
	color: var(--alt-color);
	margin: 0;
	max-width: 250px;
}

.error {
	color: #bb4444;
}

@media screen and (max-width: 900px) {
	#login-container {
		flex-direction: column;
		padding: 16px;
		width: calc(100vw - 32px);
		height: auto;
		margin-top: 32px;
		margin-bottom: 48px;
		border: none;
		gap: 48px;
	}

	#login {
		max-width: 90vw;
	}

	#login form,
	#login form input {
		width: 100%;
	}

	#login form input[type="submit"] {
		width: calc(100% + 26px);
	}

	#security,
	#security img {
		max-width: 80vw;
		/* width: 90vw */
	}
}
