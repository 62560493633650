.signupContainer {
	/* This is probably not good. */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

#background {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	pointer-events: none;
	z-index: -1;

	background: url("../assets/images/waves.png") #33336f;
	background-position: center center;
	background-size: cover;
}

@media screen and (max-width: 900px) {
	#background {
		display: none;
	}

	body {
		position: unset;
		display: block;
	}
}

#signup-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 48px;
	gap: 96px;

	width: 840px;
	/* height: 546px; */

	background: #fafafa;
	border: 1px solid #bcbacd;
	border-radius: 16px;
}

#signup {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 32px;

	width: 331px;
}

#signup .txt h1 {
	font-size: 36px;
}

#signup .txt p {
	font-size: 18px;
}

#signup .txt h1,
#signup p {
	margin: 0;
	text-align: center;
}

#signup > p {
	font-size: 18px;
	text-align: center;
}

#signup form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 16px;

	width: 331px;
	/* height: 240px; */
}

#signup form input {
	width: 305px;
	height: 48px;
	padding: 0 12px;

	font-size: 16px;

	background: #ffffff;
	border: 1px solid #bcbacd;
	border-radius: 24px;
	color: #505050;
}

#signup form input[type="submit"] {
	width: 331px;
	background: var(--alt-color);
	color: #ffffff;
}

#signup a,
#signup a:visited {
	color: black;
}

#privacy {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 40px;

	width: 380px;
	height: 433px;
}

#privacy p {
	font-size: 16px;
	text-align: center;
	color: var(--alt-color);
	margin: 0;
	max-width: 290px;
}

.error {
	color: #bb4444;
}
.success{
	color:green;
}

@media screen and (max-width: 900px) {
	#signup-container {
		flex-direction: column;
		padding: 16px;
		width: calc(100vw - 32px);
		height: auto;
		margin-top: 32px;
		margin-bottom: 48px;
		border: none;
		gap: 48px;
	}

	#signup {
		max-width: 90vw;
	}

	#signup form,
	#signup form input {
		width: 100%;
	}

	#signup form input[type="submit"] {
		width: calc(100% + 26px);
	}

	#privacy,
	#privacy img {
		max-width: 80vw;
		/* width: 90vw */
	}
}
