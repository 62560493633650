.legal-body > p, .legal-body > ul > li {
	margin-top: 36px;
}

.legal-header {
	color: var(--main-color);
	font-size: 36px;
	line-height: 120%;

	padding: 120px;
	margin: 0;
	background-image: url("../assets/images/terms-wave.png");
	background-size: contain;
}

.legal-body {
	margin: auto;
	max-width: 580px;
	width: calc(100vw - 32px);
}

.legal-list {
	list-style-type: none;
	counter-reset: legal;
	padding: 0;
	line-height: 150%;
	font-size: 16px; 
}

.legal-list > li {
	display: grid;
	grid-template-columns: 24px 1fr;
	gap: 24px;
}

.legal-list > li > p {
	margin: 0;
}

.legal-list > li:before {
	counter-increment: legal;
	content: counter(legal);
	background-color: var(--main-color);
	display: inline-block;
	text-align: center;
	width: 24px;
	height: 24px;
	color: #242424;
	font-size: 16px;
	font-weight: 700;
	margin-right: 24px;
}

.legal-list ul {
	list-style-type: "\2022\00a0\00a0";
}

.legal-list ul li {
	margin: 8px 0;
}

@media screen and (max-width: 800px) {
	.legal-header {
		padding: 40px 20px;
		text-align: center;
	}
}